import {
  disablePageScroll,
  enablePageScroll
} from 'scroll-lock';
import menuTL from '../lib/gsap';


// menu toggler with animation
const navToggler = document.querySelector('#navToggler');
navToggler.addEventListener('click', function (evt) {
  evt.preventDefault();
  const togglerButton = this;
  // const parent = this.parentElement;
  const toggledClassName = 'site-header__nav-toggler--toggled';

  // if TimeLine is NOT PAUSED
  // means that TimeLine is fully completed
  if (!menuTL.paused()) {
    menuTL.restart(); // -> restart TimeLine
  }
  menuTL.resume();

  if (!togglerButton.classList.contains(toggledClassName)) {
    disablePageScroll();
  } else {
    enablePageScroll();
  }
});

// close menu on link click
const smallBreakPoint = +getComputedStyle(document.documentElement).getPropertyValue('--sm-bp');
if (window.innerWidth <= smallBreakPoint) {
  const links = [...document.querySelectorAll('.nav__list-link')];
  links.forEach((link) => {
    link.addEventListener('click', () => {
      navToggler.click();
    });
  });
}

if (window.innerWidth > smallBreakPoint) {
  const siteSection = [...document.querySelectorAll('.site-section:nth-of-type(odd)')];

  siteSection.forEach((section) => {
    section.appendChild(section.removeChild(section.children[0]));
    console.log(section.children[0]);
  });
}

// hide header on scroll
let prevScrollPos = 0;
let currentScrollTop = 0;
const navbar = document.querySelector('.site-header__content-wrapper');
const headerHidedCLass = 'site-header__content-wrapper--hided';

document.addEventListener('scroll', () => {
  setTimeout(() => {
    const a = window.pageYOffset;
    const b = navbar.offsetHeight;

    currentScrollTop = a;

    if (prevScrollPos < currentScrollTop && a > b + 20) {
      navbar.classList.add(headerHidedCLass);
    } else if (prevScrollPos > currentScrollTop && !(a <= b)) {
      navbar.classList.remove(headerHidedCLass);
    }
    prevScrollPos = currentScrollTop;
  }, 0);
});

// shrink header on scroll
const navbarShrink = 'site-header__content-wrapper--shrink';

$(document).on("scroll", function () {
  if ($(document).scrollTop() > 300) {
    $(navbar).addClass(navbarShrink);
  } else {
    $(navbar).removeClass(navbarShrink);
  }
});

/* eslint-disable */

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
const vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});


$(document).ready(function(){  
  const filters = {};
  
  // when a link in the filters div is clicked...  
  $('#artistsListFilters input').click(function(e){  
      // e.preventDefault();  
      filters[$(this).parent().attr('class')] = $(this).attr('id');  
      const show = $('#artistsList ul li').filter(function(){ 
          for(let k in filters)
              if(
                 filters.hasOwnProperty(k) &&
                 !$(this).hasClass(filters[k])
              )
              return false;
          return true;
      });
      show.fadeIn();
      $('#artistsList ul li').not(show).hide();
      $('pre:first').html(JSON.stringify(filters));     
  });  
  
  $( '#artistsAll' ).click ();
}); 