import { TimelineLite, Power2, TimelineMax } from 'gsap';

import ScrollMagic from 'scrollmagic';
import "scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap";
import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";

// menu timelines
const mainTL = new TimelineLite({ delay: 0.5 });
const menuTL = new TimelineLite();

mainTL.timeScale(2);
menuTL.timeScale(2);

// items to animate
const navLinks = '.nav__list-link';
const navLogo = '.site-header__logo';

// block actions
const toggleActionBlocker = () => {
	document.querySelector('.actions-blocker').classList.toggle('blocked');
};
// toggle nav btn
const toggleClass = () => {
	document.querySelector('#navToggler').classList.toggle('site-header__nav-toggler--toggled');
};

// logo
mainTL.fromTo(navLogo, 1.25, {y: -200, opacity: 0}, {y:0, opacity:1 }, 0.25);
// menuTL
menuTL
	.addPause()
	.to('.site-header', 0, { onStart: toggleClass, onComplete: toggleActionBlocker })
	.staggerTo('.line', 0.1, { scaleX: 0 }, 0.05)
	.to('.close', 0.15, { scale: 1, rotationZ: 45, x: '-50%', y: '-50%' })
	.to('.site-header__overlay', 0.25, { ease: Power2.easeInOut, scaleX: 1, transformOrigin: 'left center' }, '-=0.15')
	.to('.site-header__logo-text', 0.1, { autoAlpha: 1 })
	.staggerTo(navLinks, 0.75, { autoAlpha: 1, y: 0, ease: Power2.easeInOut }, 0.3, 0, toggleActionBlocker)
	.addPause()
	.staggerTo(navLinks, 0.5, { autoAlpha: 0, yPercent: 100, ease: Power2.easeInOut }, 0.1)
	.to('.site-header__logo-text', 0.1, { autoAlpha: 0 })
	.to('.site-header__overlay', 0.25, { ease: Power2.easeInOut, scaleX: 0, transformOrigin: 'right center' })
	.to('.close', 0.15, { scale: 0, rotationZ: 0, onComplete: toggleClass })
	.staggerTo('.line', 0.1, { scaleX: 1 }, 0.05);
// mainTL
mainTL
	.staggerTo('.site-header .line', 0.5, { scaleX: 1 }, 0.1, '-=1');  

// DESKTOP ANIMATION
let ctrl = new ScrollMagic.Controller(); // Play animation at viewport on scroll

// ANIMATION BREAKPOINTS
let mediaQueryMobile = window.matchMedia("(max-width: 768px)");
let mediaQueryDesktop = window.matchMedia("(min-width: 769px)");

// Animation for MOBILE
if (mediaQueryMobile.matches) {
	$(".content").each(function(i) {
		let mainHeroTL = new TimelineMax();
		mainHeroTL.timeScale(1.5);

		let mainHero1 = $(this).find('.hero__img-wrapper');
		let mainHero3 = $(this).find('.hero__text-container h2');
		let mainHero4 = $(this).find('.hero__text-container .hero__text');
		let mainHero5 = $(this).find('.hero__text-container a');
		let mainHero6 = $(this).find('.hero__tour-wrapper');

		mainHeroTL.to(mainHero1, 1.5, {y:0, opacity:1 }, 1);
		mainHeroTL.to(mainHero3, 1.5, {x:0, opacity:1 }, 3);
		mainHeroTL.to(mainHero4, 1.5, {x:0, opacity:1 }, 3.5);
		mainHeroTL.to(mainHero5, 1.5, {x:0, opacity:1 }, 3.9);
		mainHeroTL.to(mainHero6, 1.5, {x: 0, opacity:1 }, 4.7);
	});	
}

// Animation for DESKTOPS
if(mediaQueryDesktop.matches) {
	// navigation animation
	mainTL.staggerTo(navLinks, 1, { autoAlpha: 1, y: 0 }, 0.3);
	
	$(".content").each(function(i) {
		// Timelines
		let mainHeroTL = new TimelineMax();
		let mainHistoryTL = new TimelineMax();
		let mainCultureTL = new TimelineMax();
		let mainCollectionsTL = new TimelineMax();
		let mainArtistsTL = new TimelineMax();
		let mainArtTL = new TimelineMax();
		let mainLibraryTL = new TimelineMax();

		// play TL faster
		mainHeroTL.timeScale(1.5);
		mainHistoryTL.timeScale(2.5);
		mainCultureTL.timeScale(2.5);
		mainCollectionsTL.timeScale(2.5);
		mainArtistsTL.timeScale(2.5);
		mainArtTL.timeScale(2.5);
		mainLibraryTL.timeScale(2.5);

		// LIBRARY SECTION
		let heroAllTL = new TimelineMax();

		let heroAllPages = $(this).find(".img__hero-inner");
		heroAllTL.fromTo(heroAllPages, 1.5, {y:50, opacity:0 }, {y:0, opacity:1 }, 1.45);

		// HERO SECTION
		let mainHero1 = $(this).find('.hero__img-wrapper');
		let mainHero2 = $(this).find('.hero__pattern');
		let mainHero3 = $(this).find('.hero__text-container h2');
		let mainHero4 = $(this).find('.hero__text-container .hero__text');
		let mainHero5 = $(this).find('.hero__text-container a');
		// let mainHero6 = $(this).find('.hero__tour-wrapper');

		// mainHeroTL.fromTo(mainHero1, 1.5, {y: 100, opacity: 0}, {y:0, opacity:1 }, 1);
		mainHeroTL.to(mainHero1, 1.5, {y:0, opacity:1 }, 1);
		mainHeroTL.to(mainHero2, 1.5, {x: 0, opacity:1 }, 2.75);
		mainHeroTL.to(mainHero3, 1.5, {y:0, opacity:1 }, 3);
		mainHeroTL.to(mainHero4, 1.5, {y:0, opacity:1 }, 3.50);
		mainHeroTL.to(mainHero5, 1.5, {y:0, opacity:1 }, 3.75);
		// mainHeroTL.to(mainHero6, 1.5, {x: -250, opacity:1 }, 4.75);

		// HISTORY SECTION 
		let mainHistory1 = $(this).find(".main-history__text-wrapper h2");
		let mainHistory2 = $(this).find(".main-history__text-wrapper p");
		let mainHistory3 = $(this).find(".main-history__text-wrapper a");
		let mainHistory4 = $(this).find(".main-history__img-wrapper");
		let mainHistory5 = $(this).find(".main-history__slider-wrapper .glide__slide");
		let mainHistory6 = $(this).find(".main-history__slider-wrapper .glide__arrows");
		let mainHistory7 = $(this).find(".main-history__svg-text");
		
		mainHistoryTL.to(mainHistory1, 1.5, {y:0, opacity:1 }, 0.5);
		mainHistoryTL.to(mainHistory2, 1.5, {y:0, opacity:1 }, 1);
		mainHistoryTL.staggerTo(mainHistory5, 1.25, {y: 0, opacity:1, stagger:0.35});
		mainHistoryTL.to(mainHistory3, 1.5, {y:0, opacity:1 }, 1.25);
		mainHistoryTL.to(mainHistory4, 1.5, {x:0,y:0,z:0, opacity:1 }, 2);
		mainHistoryTL.to(mainHistory6, 1.25, {y:0, opacity:1 }, 2.75);
		mainHistoryTL.to(mainHistory7, 1.5, {opacity:1 }, 3.5);

		// CULTURE SECTION
		let mainCulture1 = $(this).find(".main-culture__text-wrapper h2");
		let mainCulture2 = $(this).find(".main-culture__text-wrapper p");
		let mainCulture3 = $(this).find(".main-culture__text-wrapper a");
		let mainCulture4 = $(this).find(".main-culture__img-wrapper");
		let mainCulture5 = $(this).find(".main-culture__card-list-wrapper .card__slider-list li");
		let mainCulture6 = $(this).find(".main-culture__svg-text");

		mainCultureTL.to(mainCulture1, 1.5, {y:0, opacity:1 }, 0.5);
		mainCultureTL.to(mainCulture2, 1.5, {y:0, opacity:1 }, 1);
		mainCultureTL.to(mainCulture3, 1.5, {y:0, opacity:1 }, 1.25);
		mainCultureTL.to(mainCulture4, 1.5, {y:0, opacity:1 }, 1.75);
		mainCultureTL.staggerTo(mainCulture5, 1.5, {y: 0, opacity:1, stagger:0.35});
		mainCultureTL.to(mainCulture6, 1.5, {opacity:1 }, 2.25);
		
		// COLLECTIONS SECTION
		let mainCollections1 = $(this).find(".main-collections__img-wrapper");
		let mainCollections2 = $(this).find(".main-collections__info-text");
		let mainCollections3 = $(this).find(".main-collections__info-text h2");
		let mainCollections4 = $(this).find(".main-collections__info-text p");
		let mainCollections5 = $(this).find(".main-collections__info-text a");
		let mainCollections6 = $(this).find(".main-collections__img-item--first .main-collections__card-wrapper");
		let mainCollections7 = $(this).find(".main-collections__link--first");
		let mainCollections8 = $(this).find(".main-collections__img-item--second .main-collections__card-wrapper");
		let mainCollections9 = $(this).find(".main-collections__link--second");
		
		mainCollectionsTL.to(mainCollections1, 1.5, {y:0, opacity:1 }, 0.85);
		mainCollectionsTL.to(mainCollections2, 1.5, {opacity:1 }, 1.45);
		mainCollectionsTL.to(mainCollections3, 1.5, {x:0, opacity:1 }, 1.75);
		mainCollectionsTL.to(mainCollections4, 1.5, {x:0, opacity:1 }, 2.25);
		mainCollectionsTL.to(mainCollections5, 1.5, {x:0, opacity:1 }, 2.5);
		mainCollectionsTL.staggerTo(mainCollections6, 1.75, {y: 0, opacity:1, stagger:0.35});
		mainCollectionsTL.to(mainCollections7, 1.5, {y:0, opacity:1 }, 5.25);
		mainCollectionsTL.staggerTo(mainCollections8, 1.75, {y: 0, opacity:1, stagger:0.35});
		mainCollectionsTL.to(mainCollections9, 1.5, {y:0, opacity:1 }, 8.15);

		// ARTISTS SECTION
		let mainArtists1 = $(this).find(".main-artists__img-wrapper");
		let mainArtists2 = $(this).find(".main-artists__info-text h2");
		let mainArtists3 = $(this).find(".main-artists__info-text p");
		let mainArtists4 = $(this).find(".main-artists__info-text a");
		let mainArtists5 = $(this).find(".main-artists__card-list-item");

		mainArtistsTL.to(mainArtists1, 1.25, {opacity:1 }, 0.85);
		mainArtistsTL.to(mainArtists2, 1.5, {y:0, opacity:1 }, 1.45);
		mainArtistsTL.to(mainArtists3, 1.5, {y:0, opacity:1 }, 1.75);
		mainArtistsTL.to(mainArtists4, 1.5, {y:0, opacity:1 }, 2.25);
		mainArtistsTL.staggerTo(mainArtists5, 1.75, {x: 0, opacity:1, stagger:0.35});

		// ART SECTION
		let mainArt1 = $(this).find(".main-art__info-wrapper h2");
		let mainArt2 = $(this).find(".main-art__info-wrapper p");
		let mainArt3 = $(this).find(".main-art__info-wrapper a");
		let mainArt4 = $(this).find(".main-art__img");
		let mainArt5 = $(this).find(".main-art__svg-text");
		let mainArt6 = $(this).find(".main-art__cards-wrapper");

		mainArtTL.to(mainArt1, 1.5, {y:0, opacity:1 }, 1.45);
		mainArtTL.to(mainArt2, 1.5, {y:0, opacity:1 }, 1.75);
		mainArtTL.to(mainArt3, 1.5, {y:0, opacity:1 }, 2.25);
		mainArtTL.to(mainArt4, 1.5, {x:0, opacity:1 }, 2.5);
		mainArtTL.to(mainArt5, 1.5, {y:0, opacity:1 }, 2.75);
		mainArtTL.to(mainArt6, 1.5, {y:0, opacity:1 }, 3.5);

		// LIBRARY SECTION
		let mainLibrary1 = $(this).find(".main-library__img-wrapper");
		let mainLibrary2 = $(this).find(".main-library__info-wrapper h2");
		let mainLibrary3 = $(this).find(".main-library__info-wrapper p");
		let mainLibrary4 = $(this).find(".main-library__info-wrapper a");
		let mainLibrary5 = $(this).find(".main-library__cards-wrapper");
		let mainLibrary6 = $(this).find(".main-library__card-list-item");

		mainLibraryTL.to(mainLibrary1, 1.5, {x:0, opacity:1 }, 1.45);
		mainLibraryTL.to(mainLibrary2, 1.5, {x:0, opacity:1 }, 1.45);
		mainLibraryTL.to(mainLibrary3, 1.5, {x:0, opacity:1 }, 1.75);
		mainLibraryTL.to(mainLibrary4, 1.5, {x:0, opacity:1 }, 2.25);
		mainLibraryTL.to(mainLibrary5, 1.5, {opacity:1}, 2.45);
		mainLibraryTL.staggerTo(mainLibrary6, 1, {y: 0, opacity:1, stagger:0.25});

		// SCROLL MAGIC scenes
		var scene1 = new ScrollMagic.Scene({
			triggerElement: this,
			duration: 0, // 1px and more for play animation with scroll
			triggerHook: 0,
			offset: 0,
			reverse: false // no animation on scroll to top
		})
		.setTween(mainHeroTL)
		// .addIndicators()
		.addTo(ctrl);
		
		var scene2 = new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
				triggerHook: 0.5,
				offset: 0,
				reverse: false
		})
		.setTween(mainHistoryTL)
		.addTo(ctrl);

		var scene3 = new ScrollMagic.Scene({
			triggerElement: this,
			duration: 0,
			triggerHook: 0.5,
			offset: 0,
			reverse: false
		})
		.setTween(mainCultureTL)
		.addTo(ctrl);
		
		var scene4 = new ScrollMagic.Scene({
			triggerElement: this,
			duration: 0,
			triggerHook: 0.5,
			offset: 0,
			reverse: false
		})
		.setTween(mainCollectionsTL)
		.addTo(ctrl);
		
		var scene5 = new ScrollMagic.Scene({
			triggerElement: this,
			duration: 0,
			triggerHook: 0.5,
			offset: 0,
			reverse: false
		})
		.setTween(mainArtistsTL)
		.addTo(ctrl);
		
		var scene6 = new ScrollMagic.Scene({
			triggerElement: this,
			duration: 0,
			triggerHook: 0.5,
			offset: 0,
			reverse: false
		})
		.setTween(mainArtTL)
		.addTo(ctrl);

		var scene7 = new ScrollMagic.Scene({
			triggerElement: this,
			duration: 0,
			triggerHook: 0.5,
			offset: 0,
			reverse: false
		})
		.setTween(mainLibraryTL)
		.addTo(ctrl);

			// Add Scene to ScrollMagic Controller
			ctrl.addScene(scene1);
			ctrl.addScene(scene2);
			ctrl.addScene(scene3);
			ctrl.addScene(scene4);
			ctrl.addScene(scene5);
			ctrl.addScene(scene6);
			ctrl.addScene(scene7);
		});
}

export default menuTL;
