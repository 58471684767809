import Glide from "@glidejs/glide";

export default function () {
  if (document.querySelector("#main-history")) {
    new Glide("#main-history", {
      type: "carousel",
      startAt: 0,
      perView: 3,
      gap: 15,
      peek: {
        before: 0,
        after: 50
      },
      breakpoints: {
        320: {
          perView: 1.25,
          gap: 15,
          peek: {
            before: 0,
            after: 45
          },
        },
        375: {
          perView: 1.25
        },
        414: {
          perView: 1.25
        },
        768: {
          perView: 2
        },
        1280: {
          perView: 2.5,
          gap: 15,
          peek: {
            before: 0,
            after: 35
          }
        },
        1366: {
          perView: 2,
          peek: {
            before: 0,
            after: 75
          }
        },
        1920: {
          perView: 3
        }
      },
      animationDuration: 350,
    }).mount();
  }

  if (document.querySelector("#collection-collection")) {
    new Glide("#collection-collection", {
      type: "carousel",
      startAt: 0,
      perView: 3,
      gap: 15,
      peek: {
        before: 0,
        after: 45
      },
      breakpoints: {
        320: {
          perView: 1,
        },
        1680: {
          perView: 2,
        }
      }
    }).mount();
  }

  if (document.querySelector("#collection-exhibitions")) {
    new Glide("#collection-exhibitions", {
      type: "carousel",
      startAt: 0,
      perView: 3,
      gap: 15,
      peek: {
        before: 0,
        after: 45
      },
      breakpoints: {
        320: {
          perView: 1,
        },
        1680: {
          perView: 2,
        }
      }
    }).mount();
  }

  // glideCollections.disable();
}
