$(document).ready(function(){
    /*ПРОВЕРЯЕМ НАЖАТА ЛИ КНОПКА ОТПРАВКИ*/
    $('#btn_submit').click(function(){
        // собираем данные с формы
        let userName = $('#name').val();
        let userEmail = $('#email').val();
        let textComment = $('#text_comment').val();
        // отправляем данные
        $.ajax({
            url: "sendmail.php", // куда отправляем
            type: "post", // метод передачи
            data: { // что отправляем
                "name": userName,
                "email": userEmail,
                "text_comment": textComment,
            },
            error:function(){$("#erconts").html("Произошла ошибка!");}, 
            /* если произойдет ошибка в элементе с id erconts выведится сообщение*/                 
            beforeSend: function() {                     
                $("#erconts").html("Отправляем данные...");                   
            },                 
            success: function(result){                     
                /* В случае удачной обработки и отправки выполнится следующий код*/                     
                $('#erconts').html(result);                     
                console.log("ntcn");          
            }  
        });
    });
});

// "use stict";

// document.addEventListener("DOMContentLoaded", function () {
//   const form = document.getElementById("form");
//   form.addEventListener("submit", formSend);

//   async function formSend(e) {
//     e.preventDefault();

//     let error = formValidate(form);

//     let formData = new FormData(form);

//     if (error === 0) {
//       form.classList.add("._sending");
//       let response = await fetch("sendmail.php", {
//         method: "POST",
//         body: formData,
//       });
//       if (response.ok) {
//         let result = await response.json();
//         alert(result.message);
//         formPreview.innerHTML = "";
//         form.reset();
//         form.classList.remove("_sending");
//       } else {
//         alert("Error");
//         form.classList.remove("_sending");
//       }
//     } else {
//       console.log("zapolni polya");
//     }
//   }

//   function formValidate(form) {
//     let error = 0;
//     let formReq = document.querySelectorAll("._req");

//     for (let index = 0; index < formReq.length; index++) {
//       const input = formReq[index];
//       formRemoveError(input);

//       if (input.classList.contains("__email")) {
//         if (emailTest(input)) {
//           formAddError(input);
//           error++;
//         } else {
//           if (input.value === "") {
//             formAddError(input);
//             error++;
//           }
//         }
//       }
//     }
//     return error;
//   }
//   function formAddError(input) {
//     input.parentElement.classList.add("_error");
//     input.classList.add("_error");
//   }
//   function formRemoveError(input) {
//     input.parentElement.classList.remove("_error");
//     input.classList.remove("_error");
//   }
//   function emailTest(input) {
//     return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/.test(input.value);
//   }
// });
